
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import roc_list, {
  business_natures,
  group_types,
  industry_types,
  business_types,
} from "@/core/data/genericData";
import axios from "axios";
import { iconProps } from "element-plus";

export default defineComponent({
  name: "add-company-modal",
  components: {},
  methods: {
    onChange() {
      this.formData.entity_select = "";
    },
  },

  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const addAccountModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    //{ entity_name: "Metalxperts (india) LLP", entity_id: 1 },
    // { entity_name: "new", entity_id: 2 },
    // { entity_name: "last", entity_id: 3 },

    onMounted(async () => {
      await setNaturesData();
      await setAtypeData();
      await setTransNature();
      await setAcGroup();
      await setAcRef();
      //await setEntity("");
    });

    interface IAccountRefI {
      reference_table_id;
      reference_alias;
      reference_table_name;
      // reference_table_type;
      // reference_table_field_name;
    }

    interface IAccountGroupI {
      group_name;
      group_id;
      parent_group;
    }

    interface IAccountTypesI {
      account_type_id;
      account_type;
    }

    interface IAccountTranNatI {
      transaction_nature_id;
      transaction_nature;
    }

    interface InaturesData {
      group_nature_name;
      group_nature_id;
    }

    interface EntityData {
      entity_id;
      entity_name;
    }

    const entity_value = ref();
    function onClick(data) {
      entity_value.value = data;
    }

    var entityList = ref<Array<EntityData>>([]);
    const setEntity = async (data) => {
      const db_data = {
        search_term: data,
        reference_table_id: formData.value.reference_select,
      };

      try {
        if (db_data.search_term) {
          await axios
            .post(
              "https://elogicalservices.mymetal.in/fa_reference_tables/search_entity",
              db_data,
              {
                headers: {
                  "x-api-key": "4HTvrHxuh98GvP3XWYXaUYMHgDnOuMa1aHXtDD95",
                },
              }
            )
            .then(({ data }) => {
              var entity = ref<Array<EntityData>>([]);

              for (let j = 0; j < data.data.length; j++) {
                entity.value = Array({
                  entity_id: data.data[j]["entity_id"],
                  entity_name: data.data[j]["entity_name"],
                });

                entityList.value.splice(
                  j,
                  entity.value.length,
                  ...entity.value
                );

                console.log("entityList");
                console.log(entityList);
              }
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      } catch (e) {
        console.log(e);
      }
    };

    var acRef = ref<Array<IAccountRefI>>([]);
    const setAcRef = async () => {
      //const db_data = { page: 1, records_per_page: 10 };

      try {
        await axios
          .post(
            "https://elogicalservices.mymetal.in/fa_reference_tables/list_new",

            {
              headers: {
                "x-api-key": "4HTvrHxuh98GvP3XWYXaUYMHgDnOuMa1aHXtDD95",
              },
            }
          )
          .then(({ data }) => {
            var acRefT = ref<Array<IAccountRefI>>([]);

            for (let j = 0; j < data.data.length; j++) {
              acRefT.value = Array({
                reference_table_id: data.data[j]["reference_table_id"],
                reference_alias: data.data[j]["reference_alias"],
                reference_table_name: data.data[j]["reference_table_name"],
                // reference_table_type: data.data[j]["reference_table_type"],
                // reference_table_field_name:
                //   data.data[j]["reference_table_field_name"],
              });

              acRef.value.splice(j, acRefT.value.length, ...acRefT.value);

              console.log("acRef");
              console.log(acRef);
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    var acGroup = ref<Array<IAccountGroupI>>([]);
    const setAcGroup = async () => {
      const db_data = {};

      try {
        await axios
          .post(
            "https://elogicalservices.mymetal.in/fa_groups/parent_list",
            db_data,
            {
              headers: {
                "x-api-key": "4HTvrHxuh98GvP3XWYXaUYMHgDnOuMa1aHXtDD95",
              },
            }
          )
          .then(({ data }) => {
            var acGroupT = ref<Array<IAccountGroupI>>([]);
            var k = 0;
            for (let j = 0; j < data.data.length; j++) {
              acGroupT.value = Array({
                group_name: data.data[j]["group_name"],
                group_id: data.data[j]["group_id"],
                parent_group: 0,
              });

              acGroup.value.splice(k, acGroupT.value.length, ...acGroupT.value);

              if (data.data[j]["child_data"]) {
                for (let i = 0; i < data.data[j]["child_data"].length; i++) {
                  acGroupT.value = Array({
                    group_name: data.data[j]["child_data"][i]["group_name"],
                    group_id: data.data[j]["child_data"][i]["group_id"],
                    parent_group: data.data[j]["child_data"][i]["group_id"],
                  });
                  k = k + 1;
                  acGroup.value.splice(
                    k,
                    acGroupT.value.length,
                    ...acGroupT.value
                  );
                }
              }
              k = k + 1;
            }

            console.log("acGroup");
            console.log(acGroup);
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    var transNature = ref<Array<IAccountTranNatI>>([]);
    const setTransNature = async () => {
      const db_data = { page: 1, records_per_page: 10 };

      try {
        await axios
          .post(
            "https://elogicalservices.mymetal.in/fa_transaction_natures/list",
            db_data,
            {
              headers: {
                "x-api-key": "4HTvrHxuh98GvP3XWYXaUYMHgDnOuMa1aHXtDD95",
              },
            }
          )
          .then(({ data }) => {
            var transNatureI = ref<Array<IAccountTranNatI>>([]);
            for (let j = 0; j < data.data.length; j++) {
              if (data.data[j]["transaction_nature_id"] == 0) {
                continue;
              }
              transNatureI.value = Array({
                transaction_nature: data.data[j]["transaction_nature"],
                transaction_nature_id: data.data[j]["transaction_nature_id"],
              });

              transNature.value.splice(
                j,
                transNatureI.value.length,
                ...transNatureI.value
              );
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    var naturesData = ref<Array<InaturesData>>([]);
    const setNaturesData = async () => {
      const db_data = { page: 1, records_per_page: 10 };

      try {
        await axios
          .post(
            "https://elogicalservices.mymetal.in/fa_group_natures/list",
            db_data,
            {
              headers: {
                "x-api-key": "4HTvrHxuh98GvP3XWYXaUYMHgDnOuMa1aHXtDD95",
              },
            }
          )
          .then(({ data }) => {
            var naturesDataI = ref<Array<InaturesData>>([]);
            for (let j = 0; j < data.data.length; j++) {
              if (data.data[j]["group_nature_id"] == 0) {
                continue;
              }
              naturesDataI.value = Array({
                group_nature_name: data.data[j]["group_nature_name"],
                group_nature_id: data.data[j]["group_nature_id"],
              });

              naturesData.value.splice(
                j,
                naturesDataI.value.length,
                ...naturesDataI.value
              );
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    var atypeData = ref<Array<IAccountTypesI>>([]);
    const setAtypeData = async () => {
      const db_data = { page: 1, records_per_page: 10 };

      try {
        await axios
          .post(
            "https://elogicalservices.mymetal.in/fa_account_types/list",
            db_data,
            {
              headers: {
                "x-api-key": "4HTvrHxuh98GvP3XWYXaUYMHgDnOuMa1aHXtDD95",
              },
            }
          )
          .then(({ data }) => {
            var atypeDataI = ref<Array<IAccountTypesI>>([]);
            for (let j = 0; j < data.data.length; j++) {
              if (data.data[j]["account_type_id"] == 0) {
                continue;
              }
              atypeDataI.value = Array({
                account_type: data.data[j]["account_type"],
                account_type_id: data.data[j]["account_type_id"],
              });

              atypeData.value.splice(
                j,
                atypeDataI.value.length,
                ...atypeDataI.value
              );
            }
            console.log("atypeData");
            console.log(atypeData);
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const formData = ref({
      name: "",
      description: "",
      group_select: "",
      account_type_select: "",
      reference_select: "",
      entity_select: "",
      opening_balance: "",
      transaction_nature_select: "",
      active: "",
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: "Account Name is required",
          trigger: "change",
        },
      ],
      // description: [
      //   {
      //     required: true,
      //     message: "Description is required",
      //     trigger: "change",
      //   },
      // ],
      group_select: [
        {
          required: true,
          message: "Group is required",
          trigger: "change",
        },
      ],
      account_type_select: [
        {
          required: true,
          message: "Account Type is required",
          trigger: "change",
        },
      ],
      reference_select: [
        {
          required: true,
          message: "Reference is required",
          trigger: "change",
        },
      ],
      entity_select: [
        {
          required: true,
          message: "Entity is required",
          trigger: "change",
        },
      ],
      opening_balance: [
        {
          required: true,
          message: "Opening Balance is required",
          trigger: "change",
        },
      ],
      transaction_nature_select: [
        {
          required: true,
          message: "Transaction Nature is required",
          trigger: "change",
        },
      ],
    });

    const setCompanyData = async (data) => {
      if (!formData.value.opening_balance) {
        formData.value.opening_balance = "0.00";
      }

      const db_data = {
        account_name: data.name,
        account_description: data.description,
        account_group_id: data.group_select,
        account_type_id: data.account_type_select,
        reference_table_id: data.reference_select,
        reference_entity_id: data.entity_select,
        reference_entity_name: entity_value.value,
        transaction_nature_id: data.transaction_nature_select,
        opening_amount: data.opening_balance,
        active: true,
      };

      try {
        await axios
          .post(
            "https://elogicalservices.mymetal.in/fa_master/create_account",
            db_data,
            {
              headers: {
                "x-api-key": "4HTvrHxuh98GvP3XWYXaUYMHgDnOuMa1aHXtDD95",
              },
            }
          )
          .then(({ data }) => {
            console.log(db_data);
            console.log(data);
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const submit = async () => {
      if (!formRef.value) {
        return;
      }

      await setCompanyData(formData.value);

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;

          setTimeout(() => {
            loading.value = false;

            Swal.fire({
              text: "Form has been successfully submitted!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              hideModal(addAccountModalRef.value);
            });
          }, 2000);
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      naturesData,
      addAccountModalRef,
      atypeData,
      transNature,
      acGroup,
      acRef,
      entityList,
      setEntity,
      onClick,
      entity_value,
    };
  },
});
